import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { useScrollTrigger } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Book as BookIcon,
  Instagram as InstagramIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Menu as MenuIcon,
  Web as WebIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  CardContent,
  Container,
  CssBaseline,
  Drawer,
  Fab,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { red } from "@mui/material/colors";
import clsx from "clsx";

import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import useIsMobile from "../../hooks/useIsMobile";
import { DASHBOARD, LOGIN } from "../../Routes/constants";
import { theme } from "../../theme/index";
import { getTheme, ThemeCode } from "../../theme/storage";
import AccountMenu from "../AppBar/AccountMenu";
import { getConfig, putControllerOfDevice } from "../../services/data/arcsys";
import MenuItems from "../AppBar/MenuItems";
import { AuthContext } from "../../contexts/AuthContext";
import { formatMessage } from "./parse";
import LanguageSelector from "../LanguageSelector";
import { ParametrosContext } from "../../contexts/ParametrosContext";
import { ToastContext } from "../../contexts/ToastContext";
import { CurrentMqttSubsContext } from "../../contexts/CurrentMqttSubsContext";
import { MqttContext } from "../../contexts/MqttContext";
import { NO_DATA_VALUE, OFFLINE_ERROR } from "../Controller/constants";
import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../../services/api";
import { getStatusCheck } from "../../services/data/auth";
import { getUserById } from "../../services/data/user";
import { ArcsysContext } from "../../contexts/ArcsysProviderContext";
import { SectorsContext } from "../../contexts/SectorContext";
import { AccordionMacListContext } from "../../contexts/AccordeonOrder";
import { DashboardFilterContext } from "../../contexts/DashboardFilterContext";
import { TagsContext } from "../../contexts/TagsContext";

const drawerWidth = 220;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

// Style do drawer (menu lateral)
const useStyles = makeStyles((t) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    transition: "width 0.3s",
  },
  drawerMobile: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: t.spacing(7) + 1,
    [t.breakpoints.up("sm")]: {
      width: t.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: t.spacing(0, 1),
    ...t.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
}));

interface LayoutProps {
  children: JSX.Element;
}

let devicesData: any = {};
let devicesDataState: any = {};

let offlineDelayCounter = 0;

const Layout = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setTheme } = React.useContext(ThemeModeContext);
  const { setMacsToSubscribe, setDevicesOffline } =
    React.useContext(ArcsysContext);
  const { setSectors } = React.useContext(SectorsContext);
  const { setMqtt } = React.useContext(MqttContext);
  const { setAccordionMacList } = React.useContext(AccordionMacListContext);
  const { setCurrentMqtt } = React.useContext(CurrentMqttSubsContext);
  const { setTag, setIsOfflineDevicesStored } = React.useContext(
    DashboardFilterContext
  );
  const { setTagsList } = React.useContext(TagsContext);
  const { toastSuccess, toastError } = React.useContext(ToastContext);
  const {
    token,
    authenticated,
    client,
    userArcsys,
    setUserArcsys,
    setClient,
    connectClient,
    signOut,
    userId,
    setUserId,
  } = React.useContext(AuthContext);
  const { setSubmitParametros, setParametrosData } =
    React.useContext(ParametrosContext);
  const [openDesktop, setOpenDesktop] = React.useState(true);
  const [openMobile, setOpenMobile] = React.useState(false);
  const [changeColor, setChangeColor] = React.useState(
    window.location.pathname
  );
  const [mode, setMode] = React.useState<ThemeCode>(getTheme());
  const [dateNow, setDateNow] = React.useState<number>(Date.now());
  const [offlineTimeCtrl, setOfflineTimeCtrl] = React.useState<number>();
  const [offlineTimeDevice, setOfflineTimeDevice] = React.useState<number>();
  const [offlineTimeHideCtrl, setOfflineTimeHideCtrl] =
    React.useState<number>();
  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  React.useEffect(() => {
    const fetchStatus = () => {
      // Requisição get contendo o valor configurado do tempo offline do arcsys
      getConfig().then((res) => {
        setOfflineTimeCtrl(res.data.ctrlInactiveTime);
        setOfflineTimeHideCtrl(res.data.ctrlHideTime);
        setOfflineTimeDevice(res.data.arcsysOfflineTime);
      });
    };
    fetchStatus();
    // Dar um get somente uma vez nessa requisição
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
  }, [setOfflineTimeCtrl, setOfflineTimeHideCtrl, setOfflineTimeDevice]);

  // Autenticar o usuário a cada 5 min
  React.useEffect(() => {
    if (!authenticated) return;

    const clearContextAndSignOut = () => {
      signOut().finally(() => {
        navigate(LOGIN);
        // Clear context
        setMqtt({});
        setDevicesOffline([]);
        setMacsToSubscribe([]);
        setAccordionMacList([]);
        setCurrentMqtt("");
        setTag([]);
        setTagsList([]);
        setIsOfflineDevicesStored(false);
        setSectors([]);
      });
    };

    const fetchUserArcsys = (id: string | number) => {
      getUserById(+id)
        .then((res) => {
          if (res?.data) {
            setUserArcsys(res.data);
          }
        })
        .catch(clearContextAndSignOut);
    };

    const fetchStatus = () => {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

      getStatusCheck()
        .then((res: { data: { user_id: any; }; }) => {
          const id = res.data?.user_id;
          if (id) {
            setUserId(String(id));
            if (userArcsys === undefined) {
              fetchUserArcsys(id);
            }
          }
        })
        .catch(clearContextAndSignOut);
    };

    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 300000);
    return () => clearInterval(statusInterval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, userArcsys, userId]);

  // Se o client MQTT for indefinido ele conecta ao cliente
  React.useEffect(() => {
    if (client === undefined) {
      console.log("CLIENT MQTT CONNECTED - LAYOUT");
      setClient(connectClient() as any);
    }
  }, [client, connectClient, setClient]);

  React.useEffect(() => {
    client?.on("connect", () => {
      console.log("CLIENT MQTT CONNECTION ESTABLISHED");
    });
  }, [client]);

  React.useEffect(() => {
    client?.on("error", () => {
      console.log("CLIENT MQTT CONNECTION ERROR");
    });
  }, [client]);

  React.useEffect(() => {
    client?.on("close", () => {
      console.log("CLIENT MQTT CLOSED");
      setCurrentMqtt("");
      devicesData = {};
    });
  }, [client, setCurrentMqtt]);

  React.useEffect(() => {
    client?.on("offline", () => {
      console.log("CLIENT MQTT OFFLINE");
      devicesData = {};
    });
  }, [client]);

  React.useEffect(() => {
    const fetchStatus = () => {
      setDateNow(Date.now());
      // Armazena os dados do MQTT no state 'setMqtt'
      setMqtt(devicesDataState);
      if (client) {
        (client as any)._events.message = [];
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 3000);
    return () => clearInterval(statusInterval);
  }, [client, setMqtt]);

  React.useEffect(() => {
    // Checa a cada 3 segundos se o endereço MAC está online
    const checkMacOnline = () => {
      if (
        devicesData !== undefined &&
        devicesData !== "{}" &&
        dateNow >= Date.now() - 3000
      ) {
        devicesDataState = devicesData;
        if (offlineDelayCounter > 2) {
          let keys = Object.keys(devicesDataState);
          // Para cada MAC
          for (let i = 0; i < keys.length; i++) {
            let keys_ctrl = Object.keys(devicesDataState[keys[i]]);
            // tempo para o arcsys (device) ficar offline - sair da dashboard
            if (
              parseInt(
                devicesDataState[keys[i]]["TIME"]?.lastMsg + offlineTimeDevice!
              ) < dateNow &&
              keys_ctrl.length === 1 // apenas a chave TIME
            ) {
              delete devicesDataState[keys[i]];
              continue;
            }

            // Para cada CTRLXX
            for (let j = 0; j < keys_ctrl.length; j++) {
              // Nao executar o loop para a chave TIME
              if (keys_ctrl[j] === "TIME") {
                continue;
              }

              // Checar o time da última mensagem com offlineTimeCtrl para determinar estado de offline do controlador
              if (
                parseInt(
                  devicesDataState[keys[i]][keys_ctrl[j]]?.lastMsg +
                    offlineTimeCtrl!
                ) < dateNow
              ) {
                devicesDataState[keys[i]][keys_ctrl[j]].alarme = OFFLINE_ERROR;
                devicesDataState[keys[i]][keys_ctrl[j]].estado = 0;
              }

              // Se o controlador estiver offline, retirar ele da dashboard apos offlineTimeHideCtrl segundos
              if (
                devicesDataState[keys[i]][keys_ctrl[j]].alarme === OFFLINE_ERROR
              ) {
                if (
                  parseInt(
                    devicesDataState[keys[i]][keys_ctrl[j]]?.lastMsg +
                      offlineTimeHideCtrl!
                  ) < dateNow
                ) {
                  delete devicesDataState[keys[i]][keys_ctrl[j]];
                }
              }
            }
          }
        } else {
          offlineDelayCounter++;
        }
      } else {
        offlineDelayCounter = 0;
      }
    };
    checkMacOnline();
    const macUpdateInterval = setInterval(checkMacOnline, 3000);
    return () => clearInterval(macUpdateInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offlineTimeCtrl, offlineTimeDevice, offlineTimeHideCtrl, dateNow]);

  React.useEffect(() => {
    const handleMessage = (rawTopic: string, rawMessage: Object) => {
      const mac = rawTopic.substring(0, 17);

      if (rawTopic.includes("REPLY")) {
        let sanitizedMessage = rawMessage
          .toString()
          .replace(/[^\w\s:,_\-{}"']/gi, "");
        let parsedMessage;
        try {
          parsedMessage = JSON.parse(sanitizedMessage);
        } catch (error) {
          console.error("Error parsing sanitizedMessage:", error);
          return;
        }
        if (sanitizedMessage.includes("CTRL")) {
          const ctrl_value = sanitizedMessage.slice(
            sanitizedMessage.indexOf("CTRL"),
            sanitizedMessage.indexOf("CTRL") + 6
          );

          const ctrl_id = `${mac}/${ctrl_value}`;

          setParametrosData(parsedMessage);

          const alarmFreq = parsedMessage?.[ctrl_value]?.alarme?.frequencia;
          if (alarmFreq !== undefined && alarmFreq !== NO_DATA_VALUE) {
            putControllerOfDevice(ctrl_id, {
              alarmFreq: alarmFreq * 60000,
            });
          }
        }

        if (localStorage.awaiting_reply) {
          let awaiting_reply = localStorage.awaiting_reply.split(",");
          const matchedTopic = awaiting_reply.find((topic: string) =>
            topic.startsWith(rawTopic)
          );

          if (matchedTopic) {
            awaiting_reply = awaiting_reply.filter(
              (topic: string) => topic !== matchedTopic
            );
            localStorage.setItem("awaiting_reply", awaiting_reply.join(","));

            const commandType = matchedTopic.split("=")[1];

            if (parsedMessage.respstatus === "200") {
              toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
              if (commandType === "PARAM") {
                setSubmitParametros(true);
              }
            } else {
              toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
            }
          }
        }

        client?.unsubscribe(rawTopic);
        return;
      }

      const message = formatMessage(rawMessage);
      if (message === undefined) {
        console.log("ENTROU NO MESSAGE UNDEFINED");
        return;
      }
      const ctrl =
        rawTopic.substring(22, 28) !== "SYSTEM"
          ? rawTopic.substring(22, 28)
          : false;

      if (
        ctrl &&
        rawTopic.includes("EVT") &&
        !rawTopic.includes("OFF") &&
        !rawTopic.includes("STATE")
      ) {
        if (!devicesData[mac]) {
          devicesData[mac] = {};
        }
        const mqtt = {
          lastMsg: dateNow,
          ...message,
        };
        devicesData[mac][ctrl] = mqtt;
        devicesData[mac]["TIME"] = { lastMsg: dateNow, end: 99 };
      }
    };

    client?.on("message", handleMessage);

    return () => {
      // client?.off("message", handleMessage); // Cleanup listener on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateNow, setParametrosData, client]);

  // Toggle para mudar o tema do programa
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  React.useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode, openDesktop]);

  const mobile = useIsMobile();
  const classes = useStyles();
  const theTheme = useTheme();

  const hideDrawer = () => setOpenMobile(false);

  const handleDrawerToggle = () => {
    setOpenMobile(!openMobile);
  };

  const handleDrawerOpen = () => {
    setOpenDesktop(!openDesktop);
  };

  const handleChange = (event: string) => {
    setChangeColor(event);
  };

  const handleClickLogo = () => {
    hideDrawer();
    setChangeColor(DASHBOARD);
  };

  const [drawerType, setHideDrawer] = React.useState("mobile");

  React.useEffect(() => {
    setTheme(mode);
  }, [mode, setTheme]);

  const windowWidth = window.innerWidth;
  React.useEffect(() => {
    if (windowWidth < 600) {
      setHideDrawer("mobile");
    } else {
      setHideDrawer("desktop");
    }
  }, [windowWidth]);

  React.useEffect(() => {
    const handleDeviceChange = (e: any) => {
      if (e.matches) {
        setHideDrawer("desktop");
      } else {
        setHideDrawer("mobile");
        setOpenDesktop(false);
      }
    };

    const smallDevice = window.matchMedia("(min-width: 600px)");

    smallDevice.addListener(handleDeviceChange);
  }, [windowWidth]);

  const trigger = useScrollTrigger({
    threshold: 800,
  });

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme(mode)}>
        {authenticated ? (
          <>
            <div className={classes.root}>
              <CssBaseline />
              <AppBar
                position="fixed"
                style={{
                  opacity: "93%",
                  borderRadius: 0,
                }}
                elevation={0}
              >
                <Toolbar>
                  {mobile ? (
                    <IconButton
                      aria-label="open drawer mobile"
                      edge="start"
                      onClick={handleDrawerToggle}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="open drawer mobile"
                      edge="start"
                      onClick={handleDrawerOpen}
                    >
                      <MenuIcon fontSize="large" />
                    </IconButton>
                  )}
                  <Typography
                    component={Link}
                    to={DASHBOARD}
                    onClick={() => handleChange(DASHBOARD)}
                    style={{
                      textDecoration: "inherit",
                    }}
                  >
                    {mobile ? (
                      <img
                        src={
                          mode === "light"
                            ? "/arcsys-logo.png"
                            : "/arcsys-logo-light.png"
                        }
                        alt="arcsys logo"
                        style={{
                          height: "32px",
                          marginTop: "7px",
                          padding: "0px 10px 0px 12px",
                        }}
                      />
                    ) : (
                      <img
                        src={
                          mode === "light"
                            ? "/arcsys-logo.png"
                            : "/arcsys-logo-light.png"
                        }
                        alt="arcsys logo"
                        style={{
                          height: "45px",
                          marginTop: "10px",
                          marginLeft: 20,
                        }}
                      />
                    )}
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  />
                  <LanguageSelector isMenu />
                  <Typography
                    textTransform="capitalize"
                    variant="caption"
                    fontSize={16}
                    fontWeight="bold"
                    color={mode === "dark" ? "white" : "#4d5b7c"}
                    mr={1.8}
                  >
                    {userArcsys?.name}
                  </Typography>
                  <AccountMenu
                    colorModeContext={ColorModeContext}
                    setChangeColor={setChangeColor}
                  />
                </Toolbar>
              </AppBar>
              <nav aria-label="mailbox folders">
                {drawerType === "desktop" && (
                  <Hidden xsDown implementation="css">
                    <Drawer
                      variant="permanent"
                      className={clsx(classes.drawer, {
                        [classes.drawerOpen]: openDesktop,
                        [classes.drawerClose]: !openDesktop,
                      })}
                      classes={{
                        paper: clsx({
                          [classes.drawerOpen]: openDesktop,
                          [classes.drawerClose]: !openDesktop,
                        }),
                      }}
                      sx={{
                        "& .MuiDrawer-paper": {
                          borderRadius: 0,
                          backgroundColor: "#FFFFF",
                          marginTop: mode === "light" ? 8 : 8.2,
                          transition: "width 0.3s",
                          pl: 1,
                          pr: 2,
                          borderRightWidth: 0,
                        },
                      }}
                    >
                      <Box pt={2} role="presentation">
                        <List sx={{ pb: 0 }}>
                          {MenuItems.map((menuItem) => {
                            const {
                              path,
                              text,
                              icon,
                              requireAdmin,
                              isUserAdmin,
                            } = menuItem;
                            const checkIfIsAdmin = userIsAdmin
                              ? true
                              : !isUserAdmin;
                            const show = !requireAdmin && checkIfIsAdmin;
                            return show ? (
                              <Box
                                key={path}
                                sx={{
                                  ml: 0.7,
                                  "&:hover": {
                                    backgroundColor:
                                      mode === "light"
                                        ? "#FFF2F2"
                                        : "rgb(50, 53, 70)",
                                    borderRadius: "10px",
                                  },
                                }}
                              >
                                <ListItem
                                  component={Link}
                                  color="primary"
                                  to={path}
                                  sx={{
                                    mb: 1,
                                    pl: 1.2,
                                  }}
                                  onClick={() => handleChange(path)}
                                >
                                  <Tooltip title={t(text) as string}>
                                    <ListItemIcon
                                      sx={{
                                        color:
                                          changeColor === path
                                            ? red[400]
                                            : mode === "dark"
                                            ? "#FEFEFE"
                                            : "black",
                                      }}
                                    >
                                      {icon}
                                    </ListItemIcon>
                                  </Tooltip>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        fontSize={15}
                                        fontWeight="bold"
                                      >
                                        {t(text)}
                                      </Typography>
                                    }
                                    sx={{
                                      color:
                                        changeColor === path
                                          ? red[400]
                                          : mode === "dark"
                                          ? "#FEFEFE"
                                          : "black",
                                    }}
                                  />
                                </ListItem>
                              </Box>
                            ) : null;
                          })}
                        </List>
                      </Box>
                      {openDesktop && (
                        <Box
                          style={{ marginTop: `auto` }}
                          pb={9}
                          textAlign="center"
                        >
                          <a
                            href="https://ageon.com.br/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Tooltip title="Site">
                              <IconButton>
                                <WebIcon />
                              </IconButton>
                            </Tooltip>
                          </a>
                          <a
                            href="https://blog.ageon.com.br/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Tooltip title="Blog">
                              <IconButton>
                                <BookIcon />
                              </IconButton>
                            </Tooltip>
                          </a>
                          <a
                            href="https://www.youtube.com/channel/UCMHZumz6qKQMIJqhi3dk90g"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Tooltip title="YouTube">
                              <IconButton>
                                <YouTubeIcon />
                              </IconButton>
                            </Tooltip>
                          </a>
                          <a
                            href="https://www.instagram.com/ageonelectroniccontrols/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Tooltip title="Instagram">
                              <IconButton>
                                <InstagramIcon />
                              </IconButton>
                            </Tooltip>
                          </a>
                        </Box>
                      )}
                    </Drawer>
                  </Hidden>
                )}
                {drawerType === "mobile" && (
                  <Hidden smUp implementation="css">
                    <Drawer
                      variant="temporary"
                      anchor={theTheme.direction === "rtl" ? "right" : "left"}
                      open={openMobile}
                      onClose={handleDrawerToggle}
                      classes={{
                        paper: classes.drawerMobile,
                      }}
                      ModalProps={{
                        keepMounted: true,
                      }}
                      sx={{
                        "& .MuiDrawer-paper": {
                          backgroundColor:
                            mode === "light" ? "#FFFF" : "#252525",
                          borderRadius: 0,
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          pt: 4,
                          pb: 0.6,
                          pl: 7,
                        }}
                      >
                        <Typography
                          variant="h6"
                          component={Link}
                          to={DASHBOARD}
                          onClick={handleClickLogo}
                          sx={{
                            textDecoration: "inherit",
                          }}
                        >
                          <img
                            src={
                              mode === "light"
                                ? "/arcsys-logo.png"
                                : "/arcsys-logo-light.png"
                            }
                            alt="arcsys logo"
                            style={{
                              height: "55px",
                              marginTop: "8px",
                            }}
                          />
                        </Typography>
                      </CardContent>
                      <Box pt={2} role="presentation">
                        <List onClick={hideDrawer}>
                          {MenuItems.map((menuItem) => {
                            const {
                              path,
                              text,
                              icon,
                              requireAdmin,
                              isUserAdmin,
                            } = menuItem;
                            const checkIfIsAdmin = userIsAdmin
                              ? true
                              : !isUserAdmin;
                            const show = !requireAdmin && checkIfIsAdmin;
                            return show ? (
                              <Box
                                key={path}
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      mode === "light"
                                        ? "#FFF2F2"
                                        : "rgb(50, 53, 70)",
                                  },
                                }}
                              >
                                <ListItem
                                  component={Link}
                                  color="primary"
                                  to={path}
                                  sx={{
                                    mb: 1,
                                    pl: 3,
                                  }}
                                  onClick={() => handleChange(path)}
                                >
                                  <ListItemIcon
                                    sx={{
                                      color:
                                        changeColor === path
                                          ? red[400]
                                          : mode === "dark"
                                          ? "#FEFEFE"
                                          : "black",
                                    }}
                                  >
                                    {icon}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        fontSize={15}
                                        fontWeight="bold"
                                      >
                                        {t(text)}
                                      </Typography>
                                    }
                                    sx={{
                                      color:
                                        changeColor === path
                                          ? red[400]
                                          : mode === "dark"
                                          ? "#FEFEFE"
                                          : "black",
                                    }}
                                  />
                                </ListItem>
                              </Box>
                            ) : null;
                          })}
                        </List>
                      </Box>
                      <Box
                        style={{ marginTop: `auto` }}
                        pb={2}
                        textAlign="center"
                      >
                        <a
                          href="https://ageon.com.br/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Tooltip title="Site">
                            <IconButton>
                              <WebIcon />
                            </IconButton>
                          </Tooltip>
                        </a>
                        <a
                          href="https://blog.ageon.com.br/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Tooltip title="Blog">
                            <IconButton>
                              <BookIcon />
                            </IconButton>
                          </Tooltip>
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCMHZumz6qKQMIJqhi3dk90g"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Tooltip title="YouTube">
                            <IconButton>
                              <YouTubeIcon />
                            </IconButton>
                          </Tooltip>
                        </a>
                        <a
                          href="https://www.instagram.com/ageonelectroniccontrols/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Tooltip title="Instagram">
                            <IconButton>
                              <InstagramIcon />
                            </IconButton>
                          </Tooltip>
                        </a>
                      </Box>
                    </Drawer>
                  </Hidden>
                )}
              </nav>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper
                  sx={{
                    borderRadius: 5,
                    backgroundColor: mode === "dark" ? "#181818" : "#F0F2F5",
                    border:
                      mode === "light"
                        ? "1px solid #D7D7D7"
                        : "1px solid #000000",
                    minHeight: "calc(100vh - 64px)",
                    mr: mobile ? 0 : 2,
                    ml: 0,
                  }}
                  elevation={0}
                >
                  <Container
                    maxWidth={"100%" as any}
                    sx={{
                      pt: mobile ? 2.2 : 3.2,
                      pl: mobile ? 0 : 2,
                      pr: mobile ? 0 : 2,
                    }}
                  >
                    {children}
                  </Container>
                </Paper>
              </main>
            </div>
            <Zoom in={trigger}>
              <Fab
                sx={{
                  position: "fixed",
                  bottom: 12,
                  right: 12,
                }}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0 });
                }}
                size="small"
                aria-label="scroll back to top"
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </Zoom>
          </>
        ) : (
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme(mode)}>
              <Container disableGutters maxWidth={"100%" as any}>
                {children}
              </Container>
            </ThemeProvider>
          </ColorModeContext.Provider>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Layout;
